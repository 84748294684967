import axios, { AxiosError, AxiosResponse } from 'axios';

import { useMutation } from '@tanstack/react-query';

export type IOtpVerifyRequest = {
  code: string;
  destination: string;
};

export type IOtpVerifyResponse = {
  reason: string;
  valid: boolean;
};

export function useOtpVerify() {
  return useMutation<AxiosResponse<IOtpVerifyResponse>, AxiosError, IOtpVerifyRequest>({
    mutationFn: (params) => axios.post('/user-service/otp/check', undefined, { params }),
  });
}
